@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

p {
  margin: 0 !important;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

tr.shopify-order-row:nth-of-type(4n + 3),
tr.shopify-order-row:nth-of-type(4n + 3) + * {
  background-color: rgb(249 250 251);
}

.context {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.context h1 {
  text-align: center;
  color: #fff;
  font-size: 50px;
}

.area {
  background: #16a34a;
  background: -webkit-linear-gradient(to left, #22c55e, #16a34a);
  width: 100%;
  /* height: 100vh; */
  height: 300px;
  border-radius: 10px;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li > img {
  filter: brightness(0) invert(1);
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
  background: none;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 50px;
  height: 50px;
  animation-delay: 2s;
  animation-duration: 7s;
  background: none;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 50px;
  height: 50px;
  animation-delay: 4s;
  background: none;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 13s;
  background: none;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 50px;
  height: 50px;
  animation-delay: 0s;
  background: none;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
  background: none;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
  background: none;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 65px;
  height: 65px;
  animation-delay: 15s;
  animation-duration: 40s;
  background: none;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 45px;
  height: 45px;
  animation-delay: 2s;
  animation-duration: 30s;
  background: none;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 6s;
  background: none;
}

.zindex-1 {
  z-index: -1;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

/* .App {
  font-family: sans-serif;
} */

.dropdown {
  position: relative;
  color: #333;
  cursor: default;
}

.dropdown .arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: 0.3rem;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.dropdown .arrow.open {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.dropdown .selected-value input {
  line-height: 1.5;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
  width: 100%;
}

.dropdown .options {
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.dropdown .options.open {
  display: block;
}

.dropdown .option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.dropdown .option.selected,
.dropdown .option:hover {
  background-color: #f2f9fc;
  color: #333;
}

.swiper-button-next,
.swiper-button-prev {
  color: #16a34a !important; /* Colore delle frecce */
  font-size: 24px !important; /* Dimensione delle frecce */
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 24px !important; /* Simboli delle frecce */
}

.swiper-pagination-progressbar {
  background: #ccc !important; /* Sfondo della barra */
}

.swiper-pagination-progressbar-fill {
  background: #16a34a !important; /* Colore avanzamento */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}