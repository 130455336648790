/* src/TailSpin.css */
.tail-spin {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.tail-spin-circle {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid;
  border-radius: 50%;
  animation: tail-spin 1.2s linear infinite;
  border-color: #00bfff transparent transparent transparent;
}

@keyframes tail-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
